<template>
  <div class="practice-view" style="text-align: left">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem><span class="itClass" @click="tolog(courseId)">课程详情</span></BreadcrumbItem>
      <BreadcrumbItem>练习详情</BreadcrumbItem>
    </Breadcrumb>
    <!--    <div class="bg-shadow func-bar">-->
    <!--      <div class="button-container">-->
    <!--        <Button class="filter-btn btn-shadow" size="large" type="primary" :to="`/manage/course/practice/edit/${courseId}/${practiceId}`">-->
    <!--          <svg class="icon" aria-hidden="true">-->
    <!--            <use xlink:href="#icon-bianji"></use>-->
    <!--          </svg>-->
    <!--          编辑练习-->
    <!--        </Button>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="content">
      <div style="display: flex;align-items:center">
        <div class="question-opt">
          <Tooltip class="return" placement="top" content="返回">
            <Icon type="ios-arrow-back" @click="tolog(courseId)" size="24" />
          </Tooltip>
        </div>
        <div class="title" style="background-color: white">
          <span>{{ practice.name }}</span>
        </div>
      </div>
      <div v-for="(question, index) in practice.question" :key="index" class="question-image-size-restrict">
        <div class="title">
          <div class="question-name">
            <span>题型：{{ getType(question.questionType) }}</span>
            <span>困难度：{{ getDifficulty(question.difficulty) }}</span>
          </div>
        </div>
        <div class="stem" style="">
          <div>{{ index + 1 }}.</div>
          <BaseQuestion :question="question" :show-answer="true" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import examApi from '@api/exam'
import BaseQuestion from '@components/common/questions/BaseQuestion'
import { questionType } from '@/util/questionType'
import { difficulty } from '@/util/difficulty'

export default {
  components: {
    BaseQuestion
  },
  data: function() {
    return {
      practice: {},
      itype: {},
      itChapter: {}
    }
  },
  created() {
    examApi.getPracticeDetail(this.practiceId).then(res => {
      this.practice = res.res
    })
  },

  methods: {
    tolog() {
      this.itChapter = this.$route.query.chapters
      this.$router.push({
        path: `/course/detail/${this.courseId}`,
        query: { itChapter: this.itChapter }
      })
    },
    getDifficulty(difficulty) {
      switch (difficulty) {
        case 0:
          return '简单'
        case 1:
          return '中等'
        case 2:
          return '困难'
      }
    },
    getType(type) {
      switch (type) {
        case 0:
          return '判断题'
        case 1:
          return '单选题'
        case 2:
          return '多选题'
        case 3:
          return '填空题'
        case 4:
          return '解答题'
        case 5:
          return '代码题'
      }
    }
  },
  computed: {
    courseId() {
      return Number(this.$route.params.courseId)
    },
    practiceId() {
      return Number(this.$route.params.practiceId)
    }
  },
  filters: {
    questionType(type) {
      return questionType[type].label
    },
    difficulty(number) {
      return difficulty[number].label
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';

.practice-view {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;

  .func-bar {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 80px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;

    .filter-btn {
      margin-right: 20px;
      min-width: 100px;
    }
  }

  .content {
    box-shadow: 0px 0px 3px 0px rgba(7, 27, 41, 0.1);
    background-color: white;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #e4e9ef;
    font-size: 16px;
    font-weight: bold;
    color: @font-color-content;

    .end-time {
      color: @font-color-tip;
      font-size: 12px;
    }

    .question-name {
      font-size: 12px;

      span + span {
        margin-left: 10px;
        padding: 0 10px;
        border-left: 1px solid @font-color-base;
      }
    }
  }

  .stem {
    padding: 10px 20px;
    display: flex;
  }
}
.question-opt {
  float: right;
}
.return:hover {
  cursor: pointer;
}
</style>
